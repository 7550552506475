type TConfig = {
	fullName: string;
	description?: string;
	company?: string;
	headline: string;
	socials: {
		link?: string;
		icon: string;
		label?: string;
		type?: "solid" | "brands";
	}[];
	copyright?: string;
	tool?: {
		link?: string;
		label?: string;
	};
};

const Config: TConfig = {
	fullName: "Adam Jensen",
	description: "Never asked for this",
	company: "Cyborg",
	headline: "Frontend Developer",
	socials: [
		{
			link: "/",
			icon: "fa-twitter",
			label: "Twitter",
		},
		{
			link: "/",
			icon: "fa-facebook-f",
			label: "Facebook",
		},
		{
			link: "/",
			icon: "fa-dribbble",
			label: "Dribble",
		},
		{
			link: "/",
			icon: "fa-github",
			label: "Github",
		},
		{
			link: "/",
			icon: "fa-envelope",
			label: "Email",
			type: "solid",
		},
	],
	copyright: "Untitled. Design:",
	tool: {
		link: "https://react.dev/",
		label: "React",
	},
};

export default Config;
