import React from "react";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
	return (
		<div id="wrapper" className="App">
			<div id="bg" />
			<div id="overlay" />
			<div id="main">
				<Header />
				<Footer />
			</div>
		</div>
	);
}

export default App;
