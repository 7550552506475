import React from "react";
import Config from "../config";

const Footer = () => {
	return (
		<footer id="footer">
			{Config.copyright && (
				<span className="copyright">
					&copy; {Config.copyright}{" "}
					{Config.tool?.label && (
						<a href={Config.tool.link}>{Config.tool.label}</a>
					)}
				</span>
			)}
		</footer>
	);
};

export default Footer;
