import React from "react";
import Config from "../config";

const Header = () => {
	return (
		<header id="header">
			<h1>{Config.fullName}</h1>
			<p>
				{Config.headline || ""} &nbsp;&bull;&nbsp;{" "}
				{Config.company || ""} &nbsp;&bull;&nbsp;
				{Config.description || ""}
			</p>
			<nav>
				<ul>
					{Config.socials.map((social, index) => (
						<li key={index}>
							<a
								href={social.link || "/"}
								className={`icon ${social.type || "brands"} ${
									social.icon
								}`}
							>
								<span className="label">{social.label}</span>
							</a>
						</li>
					))}
				</ul>
			</nav>
		</header>
	);
};

export default Header;
